require('normalize.css');
require('./style.sass');
const TWEEN = require('@tweenjs/tween.js')
window.TWEEN = TWEEN

import "@fontsource/open-sans";
import "@fontsource/roboto";
import "@fontsource/roboto-condensed";
import "@fontsource/roboto-condensed/300.css";

var isMobile = window.matchMedia('(max-device-width: 812px)').matches
const scrollDuration = 400

window.$ = function(x){
    return document.querySelector(x)
}

var email_localpart = 'mail';
var email_domain = 'dieter.wang';
document.querySelector('#email').href = `mailto:${email_localpart}@${email_domain}`;

document.querySelector('.menu-button').onclick = function(event){
    if (document.querySelector('.menu-item').style.display != 'block'){
        document.querySelectorAll('.menu-item').forEach((e)=>{
            e.style.display = 'block'
        })
        bind_menuitems()
    } else {
        document.querySelectorAll('.menu-item').forEach((e)=>{
            e.style.display = ''
        })
    }
}

var box = document.querySelector('#pronounce-box')
if (box != null){
    box.onclick = function(event){
        box.style.display = box.style.display == 'block' ? 'none' : 'block'

        var target = document.querySelector('#pronounce-name')
        var arrow  = document.querySelector('#pronounce-box .arrow')
        var arrow2  = document.querySelector('#pronounce-box .arrow2')

        var x1 = target.getBoundingClientRect().x
        var y1 = target.getBoundingClientRect().y
        var width = target.getBoundingClientRect().width
        var height = target.getBoundingClientRect().height
        var x0 = box.getBoundingClientRect().x
        var ypad = $('#content').getBoundingClientRect().y
        var y0 = $('#content').scrollTop
        var w0 = $('.section').getBoundingClientRect().width

        arrow.style.left = (x1 + width/2 - x0)+'px'
        arrow2.style.left = (x1 + width/2 - x0)+'px'

        box.style.top = (y1 + height + y0 - ypad - 5)+'px'
        if (isMobile)
            box.style.width = (w0 * .9) + 'px'
        else
            box.style.width = Math.min(w0*.6, 400)+'px'
    }
}


document.querySelector('html').style.visibility = 'visible'

function smoothTween(task, start, end, duration=1000, speed=30){
    var tween = new TWEEN.Tween({x: start})
        .to({x: end}, duration)
        .easing(TWEEN.Easing.Quadratic.Out)
        .onUpdate(function (object) {
            task(object.x)
        })
        .start()

    animate()
}

function animate() {
    if (TWEEN.update())
    	requestAnimationFrame(animate)
}

function bind_menuitems(){

    let menuitems = document.querySelectorAll('.menu-item')
    menuitems.forEach((item)=>{

        var section = item.attributes.target.value;

        var parent = document.querySelector('#content')
        var target = document.querySelector('#section-'+section) || document.querySelector('#subsection-'+section)

        var posParent = parent.getBoundingClientRect().top

        item.onclick = function(){
            TWEEN.removeAll()
            var posTarget = target.getBoundingClientRect().top

            var scrollFrom = parent.scrollTop
            var scrollTo = posTarget - posParent + scrollFrom
            // console.log('scrollFrom', scrollFrom)
            // console.log('posTarget', posTarget)
            // console.log('posParent', posParent)
            // console.log('scrollTo', scrollTo)

            var task = function(x){
                parent.scrollTo({ top: x, })
            }

            smoothTween(task, scrollFrom, scrollTo, scrollDuration)
        }
    })

}

bind_menuitems()
